import dayjs from 'dayjs';

import localeBg from 'dayjs/locale/bg';
import localeBs from 'dayjs/locale/bs';
import localeCs from 'dayjs/locale/cs';
import localeDa from 'dayjs/locale/da';
import localeDe from 'dayjs/locale/de';
import localeEl from 'dayjs/locale/el';
import localeEn from 'dayjs/locale/en';
import localeEs from 'dayjs/locale/es';
import localeEt from 'dayjs/locale/et';
import localeFi from 'dayjs/locale/fi';
import localeFr from 'dayjs/locale/fr';
import localeHr from 'dayjs/locale/hr';
import localeHu from 'dayjs/locale/hu';
import localeIt from 'dayjs/locale/it';
import localeLt from 'dayjs/locale/lt';
import localeLv from 'dayjs/locale/lv';
import localeNb from 'dayjs/locale/nb';
import localeNl from 'dayjs/locale/nl';
import localePl from 'dayjs/locale/pl';
import localePt from 'dayjs/locale/pt';
import localeRo from 'dayjs/locale/ro';
import localeRu from 'dayjs/locale/ru';
import localeSk from 'dayjs/locale/sk';
import localeSl from 'dayjs/locale/sl';
import localeSr from 'dayjs/locale/sr';
import localeSv from 'dayjs/locale/sv';
import localeTr from 'dayjs/locale/tr';

import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';

dayjs.locale('bg', localeBg);
dayjs.locale('bs', localeBs);
dayjs.locale('cs', localeCs);
dayjs.locale('da', localeDa);
dayjs.locale('de', localeDe);
dayjs.locale('el', localeEl);
dayjs.locale('en', localeEn);
dayjs.locale('es', localeEs);
dayjs.locale('et', localeEt);
dayjs.locale('fi', localeFi);
dayjs.locale('fr', localeFr);
dayjs.locale('hr', localeHr);
dayjs.locale('hu', localeHu);
dayjs.locale('it', localeIt);
dayjs.locale('lt', localeLt);
dayjs.locale('lv', localeLv);
dayjs.locale('nb', localeNb);
dayjs.locale('nl', localeNl);
dayjs.locale('pl', localePl);
dayjs.locale('pt', localePt);
dayjs.locale('ro', localeRo);
dayjs.locale('ru', localeRu);
dayjs.locale('sk', localeSk);
dayjs.locale('sl', localeSl);
dayjs.locale('sr', localeSr);
dayjs.locale('sv', localeSv);
dayjs.locale('tr', localeTr);

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);

export default dayjs;
