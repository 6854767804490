import routerOptions0 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/campinginfo/civ2-nuxt-3/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}