import type { LocationQueryValue, LocationQueryRaw, RouteLocationNormalizedLoadedGeneric } from 'vue-router';
import urlParams from '@/assets/json/urlParams.json';

import type { BackendRoutes } from '@/types';

// constants
import {
  RBN_CITY_SEARCH,
  RBN_COUNTRY_SEARCH,
  RBN_FEDERAL_STATE_SEARCH,
  RBN_REGION_SEARCH,
  RBN_SEARCH,
} from '@/utils/constants';

export const cleanAndSortParams = (routeBaseName: string, routeQuery: LocationQueryRaw, clearForContent = false) => {
  const isCountrySearch = routeBaseName === RBN_COUNTRY_SEARCH;
  const isCitySearch = routeBaseName === RBN_CITY_SEARCH;
  const isFederalState = routeBaseName === RBN_FEDERAL_STATE_SEARCH;
  const isRegionSearch = routeBaseName === RBN_REGION_SEARCH;

  const key = isCountrySearch || isCitySearch || isFederalState || isRegionSearch ? RBN_SEARCH : routeBaseName;
  // get valid params defined for route
  const validParams = urlParams[key];

  if (!validParams) {
    return '';
  }

  // create query object with valid params
  const query = {};
  validParams.forEach((param) => {
    if (routeQuery[param]) {
      query[param] = routeQuery[param];
    }
  });

  if (Object.keys(query).length === 0) {
    return '';
  }

  if (clearForContent && !(query.from && query.until)) {
    const keysToDelete = ['adults', 'flex', 'acco_type'];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    keysToDelete.forEach(key => delete query[key]);
  }

  // sort query
  const sortedQuery = new URLSearchParams(query);
  sortedQuery.sort();
  return `?${sortedQuery.toString()}`;
};

export const isDynamicPage = (routeBaseName: string) => {
  return ['info-all', 'sea-all', 'topic-all'].includes(routeBaseName);
};

export const getDynamicAlternateForLang = (origin: string, targetLang: string, alternates: BackendRoutes) => {
  const decodedOrigin = decodeURIComponent(origin);
  const [lang, ...path] = (decodedOrigin || '').split('?')?.[0]?.split('/').filter(seg => seg && seg !== '') ?? [];
  let out = null;

  alternates.forEach((pack) => {
    // try to find the origin uri inside a route package
    const match = pack.find((route) => {
      return route.lang === lang && route.url === `/${lang}/${path.join('/')}`;
    });
    if (!match) {
      return;
    }

    // return the final translated path from the alternates of the origin route uri
    const alternateUrl = pack.find(route => route.lang === targetLang && route.url);
    if (alternateUrl) {
      out = alternateUrl.url;
    }
  });
  return out;
};

export const identifyDynamicPage = (locale: string, routes: BackendRoutes, route: RouteLocationNormalizedLoadedGeneric) => {
  const decodedRoutePath = decodeURIComponent(route.path);

  for (let i = 0; i < routes.length; i++) {
    const dynamicRoute = routes[i];
    const matchingRoute = dynamicRoute?.find(r => r.lang === locale && r.url === decodedRoutePath);

    if (!matchingRoute) {
      const matchingHistory = dynamicRoute?.find((r) => {
        if (r.lang !== locale) {
          return false;
        }
        return !!r.history.find(h => h === decodedRoutePath);
      });
      if (matchingHistory) {
        return {
          redirect: true,
          path: matchingHistory.url,
          query: route.query,
        };
      } else {
        const matchingRouteInDifferentLocale = dynamicRoute?.find(r => r.url === decodedRoutePath);
        if (matchingRouteInDifferentLocale) {
          const s = dynamicRoute?.find(r => r.lang === locale);
          return {
            redirect: true,
            path: s.url,
            query: route.query,
          };
        }
      }
    } else {
      const matchingEnglishRoute
        = matchingRoute.lang === 'en' ? matchingRoute : dynamicRoute?.find(r => r.lang === 'en');
      const englishUrl = matchingEnglishRoute?.url;

      return {
        redirect: false,
        lang: locale,
        path: englishUrl,
        query: route.query,
      };
    }
  }

  return {
    redirect: false,
    path: null,
    query: null,
    lang: locale,
  };
};

export const getQueryValue = (queryValue: LocationQueryValue | LocationQueryValue[]): LocationQueryValue | undefined => {
  return Array.isArray(queryValue) ? queryValue[0] : typeof queryValue === 'string' ? queryValue : undefined;
};

export const getParamsValue = (param: string | string[]): string | undefined => {
  return Array.isArray(param) ? param[0] : typeof param === 'string' ? param : undefined;
};
