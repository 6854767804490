import payload_plugin_olzI0Eo2DVhci_R_Z5Dzu2bPPZl0CUNRkmi9jGXMUkA from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_ilrCZ74KaeQlNl1OK9xg2gPDec_8AFyEJSQQGeyhR2o from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fmv4fMwYdtsPrd1xgVXxYkKqrk1UxAGM_Xl1yaHWG3g from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_29FDOYyNpo_KEgojWQzG62Z6V6SaK1FO_mEXqW9XgOo from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_2dY4M0lVfnYVt68BHOM7_ZXZ81mOwZSaHnpnGBhhXs4 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xgCrPblQvvUK_rYegG9I0Cugyh0_u6qUbPDORqHBzZM from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aFUNyzFOxrsn_00gugVDAmU9wAwc5On1fv_ODrFVAnY from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7SuLDvBB8yHJXadAH9VVRsPEDLpotbMwjNp4_HhA_qw from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_g51LJAcq2pcyW69jN_C_Mnh5497i3g17T6eE9PcLyQA from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_8hfO7_YyI2NglxOdlg_xsaat49_76kc29Xfw_0GsUz8 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_650d1bf38593efbd975bbef2ff38b477/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client__9h0FSJhGC8jnH1R9bNoI4Wsmfb5mSqfP8i_nAaPzms from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_7TLF2Eo_mUC8pcxUfNBKqlWJTcUVweJLd8cqpiAfSPE from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_31fd8d069413f73da3193a6c61be39d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_2_xSATFI8aNttokRNK_rmf3sfJfyEemxm_mEub3_DOQ from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_31fd8d069413f73da3193a6c61be39d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_SfDCSP_iYs7L89g4hFtAWcP_eLvo8MGNj2DeTyPx2Xk from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_31fd8d069413f73da3193a6c61be39d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _10_init_A5HOtMSlfIvzgIF8d3NbImXOOjnNmYMAelmO39BN7_I from "/builds/campinginfo/civ2-nuxt-3/app/plugins/10.init.ts";
import _15_translate_qzkaSsCWb5j4BMrEQx_SovpR2inIH28DVprBbAYbKGo from "/builds/campinginfo/civ2-nuxt-3/app/plugins/15.translate.ts";
import _20_auth_client_bmnsnrPWNIQJQbF6zyYXxusYvtURXaNwa3iHyBCk6eY from "/builds/campinginfo/civ2-nuxt-3/app/plugins/20.auth.client.ts";
import _30_device_client_TdVm8I7_d1B4evuqMYMzqJxFJcdjJeFPxsdNEsquPUw from "/builds/campinginfo/civ2-nuxt-3/app/plugins/30.device.client.ts";
import _40_touch_client_EucFU2fPAycuqiJ1W6zPJ4KtkxXJoVYlZvtqyiKE_fs from "/builds/campinginfo/civ2-nuxt-3/app/plugins/40.touch.client.ts";
import _60_modal_VsXDRNxenjGAGvCRotIfCowf81jFF8QaoZUTqAv9IFQ from "/builds/campinginfo/civ2-nuxt-3/app/plugins/60.modal.ts";
import _70_observeVisiblity_9g3nuFwOTaAhZ6R_H73c77gYpfqqF9c8Ag9JYz3s3Ek from "/builds/campinginfo/civ2-nuxt-3/app/plugins/70.observeVisiblity.ts";
import _80_loadExternalScripts_client_R4uUldhH0OFbj763lXQtvxn4A_SLpqq6XrQG6f1FLyQ from "/builds/campinginfo/civ2-nuxt-3/app/plugins/80.loadExternalScripts.client.ts";
import _90_snowplow_client_JNeDF7Ew5LKwaA89ezd93vvlAB86dmiyOXkMsp8FjKg from "/builds/campinginfo/civ2-nuxt-3/app/plugins/90.snowplow.client.ts";
import _95_sentry_client_qaKrCn8NM_tmkIcMnucxBzsAHDWfT2aPcIF65K_1llY from "/builds/campinginfo/civ2-nuxt-3/app/plugins/95.sentry.client.ts";
import ssg_detect_44nmKSCG5WXFpOhKcDsMftOq0VWfPj2Uki6FsltA1Eo from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_31fd8d069413f73da3193a6c61be39d5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_olzI0Eo2DVhci_R_Z5Dzu2bPPZl0CUNRkmi9jGXMUkA,
  revive_payload_client_ilrCZ74KaeQlNl1OK9xg2gPDec_8AFyEJSQQGeyhR2o,
  unhead_fmv4fMwYdtsPrd1xgVXxYkKqrk1UxAGM_Xl1yaHWG3g,
  router_29FDOYyNpo_KEgojWQzG62Z6V6SaK1FO_mEXqW9XgOo,
  payload_client_2dY4M0lVfnYVt68BHOM7_ZXZ81mOwZSaHnpnGBhhXs4,
  navigation_repaint_client_xgCrPblQvvUK_rYegG9I0Cugyh0_u6qUbPDORqHBzZM,
  check_outdated_build_client_aFUNyzFOxrsn_00gugVDAmU9wAwc5On1fv_ODrFVAnY,
  chunk_reload_client_7SuLDvBB8yHJXadAH9VVRsPEDLpotbMwjNp4_HhA_qw,
  plugin_vue3_g51LJAcq2pcyW69jN_C_Mnh5497i3g17T6eE9PcLyQA,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_8hfO7_YyI2NglxOdlg_xsaat49_76kc29Xfw_0GsUz8,
  plugin_client__9h0FSJhGC8jnH1R9bNoI4Wsmfb5mSqfP8i_nAaPzms,
  switch_locale_path_ssr_7TLF2Eo_mUC8pcxUfNBKqlWJTcUVweJLd8cqpiAfSPE,
  route_locale_detect_2_xSATFI8aNttokRNK_rmf3sfJfyEemxm_mEub3_DOQ,
  i18n_SfDCSP_iYs7L89g4hFtAWcP_eLvo8MGNj2DeTyPx2Xk,
  _10_init_A5HOtMSlfIvzgIF8d3NbImXOOjnNmYMAelmO39BN7_I,
  _15_translate_qzkaSsCWb5j4BMrEQx_SovpR2inIH28DVprBbAYbKGo,
  _20_auth_client_bmnsnrPWNIQJQbF6zyYXxusYvtURXaNwa3iHyBCk6eY,
  _30_device_client_TdVm8I7_d1B4evuqMYMzqJxFJcdjJeFPxsdNEsquPUw,
  _40_touch_client_EucFU2fPAycuqiJ1W6zPJ4KtkxXJoVYlZvtqyiKE_fs,
  _60_modal_VsXDRNxenjGAGvCRotIfCowf81jFF8QaoZUTqAv9IFQ,
  _70_observeVisiblity_9g3nuFwOTaAhZ6R_H73c77gYpfqqF9c8Ag9JYz3s3Ek,
  _80_loadExternalScripts_client_R4uUldhH0OFbj763lXQtvxn4A_SLpqq6XrQG6f1FLyQ,
  _90_snowplow_client_JNeDF7Ew5LKwaA89ezd93vvlAB86dmiyOXkMsp8FjKg,
  _95_sentry_client_qaKrCn8NM_tmkIcMnucxBzsAHDWfT2aPcIF65K_1llY,
  ssg_detect_44nmKSCG5WXFpOhKcDsMftOq0VWfPj2Uki6FsltA1Eo
]