import dayjs from '@/utils/day';
import gettext from '@/utils/gettext';

export default defineNuxtPlugin({
  name: 'translate',
  parallel: true,
  async setup(nuxtApp) {
    const lang = nuxtApp.$i18n.locale.value;

    // day js locale
    dayjs.locale(getDayjsLanguageCode(lang));

    // gettext
    const gt = gettext();
    const languageFile = await import(`../locales/dist/${lang}.json`);
    gt.translations = languageFile;
    gt.current = lang;

    nuxtApp.vueApp.use(gt);

    const { $pgettext } = gt;

    // global translation helper use e.g. { $getTranslatedWordCampsite } = useNuxtApp();
    function getTranslatedWordCampsite(): string {
      return $pgettext('Just the word "Campsite", with first letter uppercased.', 'Campsite').toString();
    }

    function translationStartsWithWordCampsite(translation: string): boolean {
      return translation?.toLowerCase().trim().startsWith(getTranslatedWordCampsite().toLowerCase());
    }

    function getCampsiteNameWithCampsitePrefix(campsiteName: string): string {
      if (translationStartsWithWordCampsite(campsiteName)) {
        return campsiteName;
      }

      return `${getTranslatedWordCampsite()} ${campsiteName.trim()}`;
    }

    return {
      provide: {
        getCampsiteNameWithCampsitePrefix,
        getTranslatedWordCampsite,
        translationStartsWithWordCampsite,
      },
    };
  },
});
