export default function usePageTracking() {
  const route = useRoute();
  const campsiteStore = useCampsiteStore();
  const searchStore = useSearchStore();
  const contentStore = useContentStore();

  function getCampsiteForTracking(campsite) {
    let duration = null;
    if (route.query.from && route.query.until) {
      duration = getNumberOfNights(route.query.from, route.query.until);
    }
    const availabilities = [];
    if (campsite.availabilities?.exact) {
      const pitch = campsite.availabilities.exact.pitch;
      const acco = campsite.availabilities.exact.acco;
      if (pitch) {
        availabilities.push({
          accommodation_type: 'pitch',
          availability_type: 'exact',
          stock: pitch.stock,
          critical_stock: pitch.stock <= 5,
          duration: duration,
          start_date: route.query.from,
          end_date: route.query.until,
          position: 1,
          price: pitch.price,
          currency: 'EUR',
        });
      }
      if (acco) {
        availabilities.push({
          accommodation_type: 'acco',
          availability_type: 'exact',
          stock: acco.stock,
          critical_stock: acco.stock <= 5,
          duration: duration,
          start_date: route.query.from,
          end_date: route.query.until,
          position: pitch ? 2 : 1,
          price: acco.price,
          currency: 'EUR',
        });
      }
    }
    let pictures = (campsite.images || []).map(img => img['4_3']);
    if (!campsite.images && campsite.media?.images) {
      pictures = (campsite.media.images || []).map(img => img.url);
    } else if (campsite.media && !campsite.media.images) {
      pictures = campsite.media.slice(0, 3).map(img => img.images.small);
    }
    let numReviews = null;
    if (campsite.rating_count !== undefined && campsite.rating_count !== null) {
      numReviews = campsite.rating_count;
    } else if (campsite.rating_guest?.rating_count !== undefined && campsite.rating_guest?.rating_count !== null) {
      numReviews = campsite.rating_guest?.rating_count;
    }

    const rnd = p => Math.round((p || 0) * 100) / 100;

    return {
      id: campsite.id,
      camping_id: campsite.camping_id || null,
      slug: campsite.slug,
      name: campsite.name || campsite.meta?.name,
      booked_products: toRaw(campsite.purchases),
      pictures: pictures,
      rating_avg_overall: campsite.rating_avg_overall || campsite.rating_campsite?.rating_avg_overall || null,
      reviews: numReviews,
      price1: rnd(campsite.price?.price1) || null,
      price_off_season: rnd(campsite.price?.price_off_season) || null,
      availabilities,
    };
  }

  const breadcrumbChildren = computed(() => {
    const crumbs = ['Home'];
    const loc = searchStore.location;
    if (!loc) {
      return crumbs;
    }
    const country = loc.country;
    const federalState = loc.federal_state;
    if (country) {
      crumbs.push(country.name_en);
    }
    if (federalState) {
      crumbs.push(federalState.name_en);
    }
    return crumbs;
  });

  const breadcrumb = computed(() => {
    const crumbs = [...breadcrumbChildren.value];
    if (searchStore.location) {
      crumbs.push(searchStore.location.name_en);
    }
    return crumbs;
  });

  function getSerpOrMapProps(map: boolean = false) {
    const locationData = {};
    const loc = searchStore.location;
    if (loc) {
      const country = loc.country;
      const federalState = loc.federal_state;
      if (country) {
        locationData.country = {
          id: country.id,
          name: country.name_en,
          slug: country.slugs.en,
        };
      }
      if (federalState) {
        locationData.federal_state = {
          id: federalState.id,
          name: federalState.name_en,
          slug: federalState.slugs.en,
        };
      }
      locationData[loc.type] = {
        id: loc.id,
        name: loc.name_en,
        slug: loc.slugs.en,
      };
    }

    const campsites = [];
    if (!map && searchStore.resultItems) {
      searchStore.resultItems.forEach((campsite) => {
        campsites.push(getCampsiteForTracking(campsite));
      });
    } else if (map && searchStore.mapResult?.count) {
      searchStore.mapResult.results.forEach((campsite) => {
        if (campsite.id) {
          campsites.push(getCampsiteForTracking(campsite));
        }
      });
    }

    return {
      breadcrumb: breadcrumb.value,
      search: route.query,
      ...locationData,
      campsites,
    };
  };

  function getSerpOrMapSearchProps(map: boolean = false) {
    return {
      ...getSerpOrMapProps(map),
      breadcrumb: ['Home', map ? 'Map' : 'Search'],
    };
  }

  function getCpdpProps(subPages: string[] | null = null) {
    const campsite = campsiteStore.campsite || campsiteStore.campsiteFromMedia || {};
    const meta = campsite.meta || campsite;
    const country = meta.country;
    const federalState = meta.federal_state;
    const city = meta.city_obj;
    const regions = campsite.regions;
    const locationData = {};
    const crumbs = ['Home'];
    if (country) {
      crumbs.push(country.name_en);
      locationData.country = {
        id: country.id,
        name: country.name_en,
        slug: country.slugs.en,
      };
    }
    if (federalState) {
      crumbs.push(federalState.name_en);
      locationData.federal_state = {
        id: federalState.id,
        name: federalState.name_en,
        slug: federalState.slugs.en,
      };
    }
    if (city) {
      crumbs.push(city.name_en);
      locationData.city = {
        id: city.id,
        name: city.name_en,
        slug: city.slugs.en,
      };
    }
    if (regions) {
      locationData.regions = regions.map(region => ({
        id: region.id,
        name: region.name_en,
        slug: region.slugs.en,
      }));
    }

    return {
      breadcrumb: [...crumbs, meta.name, ...(subPages || [])],
      campsites: [
        getCampsiteForTracking(campsite),
      ],
      search: route.query,
      ...locationData,
    };
  }

  const defaultBreadcrumb = computed(() => {
    const pathSegments: string[] = route.path.split('/').filter(p => !!p);
    pathSegments.shift();
    return [
      'Home', ...pathSegments,
    ];
  });

  function getContentPageProps(pageType: string, isOverview: boolean = false) {
    const breadcrumb = ['Home', pageType.charAt(0).toUpperCase() + pageType.slice(1)];
    if (!isOverview) {
      let title = (contentStore.meta || []).filter(item => item.name === 'title');
      if (title.length) {
        title = title[0].content;
      } else {
        title = route.path.split('/').filter(p => !!p).pop() || 'Content';
      }
      breadcrumb.push(title);
    }
    return {
      breadcrumb,
    };
  }

  const pageCategories = {
    'index': null,
    'country-countrySlug': () => getSerpOrMapProps(),
    'country-countrySlug-federalStateSlug': () => getSerpOrMapProps(),
    'city-citySlug': () => getSerpOrMapProps(),
    'popularRegion-regionSlug': () => getSerpOrMapProps(),
    'search': () => getSerpOrMapSearchProps(),
    'searchMap': () => getSerpOrMapSearchProps(true),
    'campsite': () => getSerpOrMapProps(),
    'campsite-identifier': () => getCpdpProps(),
    'campsite-identifier-inquire': () => getCpdpProps(['inquire']),
    'campsite-identifier-inquire-confirm': () => getCpdpProps(['inquire', 'confirm']),
    'campsite-identifier-media': () => getCpdpProps(['media']),
    'campsite-identifier-rate': () => getCpdpProps(['rate']),
    'campsite-identifier-rate-confirm': () => getCpdpProps(['rate', 'confirm']),
    'campsite-identifier-rate-confirm-oauth': () => getCpdpProps(['rate', 'confirm', 'oauth']),
    'campsite-identifier-reviews': () => getCpdpProps(['reviews']),
    'campsite-identifier-reviews-id': () => getCpdpProps(['reviews', 'detail']),
    'campsite-identifier-upload-images': () => getCpdpProps(['upload', 'images']),
    'campsite-identifier-upload-videos': () => getCpdpProps(['upload', 'videos']),
    'info': () => getContentPageProps('info', true),
    'info-all': () => getContentPageProps('info'),
    'topic': () => getContentPageProps('topic', true),
    'topic-all': () => getContentPageProps('topic'),
    'sea-all': () => getContentPageProps('sea'),
  };

  function hasAdSlots() {
    return document.getElementsByClassName('ci-ad-slot').length > 0;
  }

  function getPageObjectForTracking() {
    const pageName = (route.name || '').replace(/___[a-z]{2}$/, '');
    const pageObj = pageCategories[pageName];
    const lang = route.path.split('/')[1];
    let page = {
      page: {
        type: pageName,
        has_ads: hasAdSlots(),
        language: lang,
      },
    };
    if (pageObj) {
      if (typeof pageObj === 'function') {
        page = {
          ...page,
          ...pageObj(),
        };
      } else {
        page = {
          ...page,
          ...pageObj,
        };
      }
    } else {
      page = {
        ...page,
        breadcrumb: defaultBreadcrumb.value,
      };
    }
    return page;
  }

  return {
    getPageObjectForTracking,
  };
}
